<template>
  <div class="work-space">
    <div class="tool-bar" v-if="is_pc">
      <div class="left">
        <a-tooltip placement="bottom" :title="$t('back')" @click="backPage">
			<a-icon type="left-circle" style="font-size:26px;color:#666"/>
        <!--  <i class="iconfont icon-undo"></i> -->
        </a-tooltip>
        <!-- <a-tooltip placement="bottom" title="重做">
          <i class="iconfont icon-Redo"></i>
        </a-tooltip> -->
      </div>
      <div class="mid">
        <!-- <a-tooltip class="tooltip" placement="bottom" title="Double click the selected part of the model, double-click the blank space around the model to deselect the part, and right-click to delete the material.">
			<i class="iconfont icon-question1"></i>
		</a-tooltip> -->
        <!-- <a-tooltip class="tooltip" placement="bottom" title="网格">
          <i class="iconfont icon-Grid"></i>
        </a-tooltip> -->
        <!-- <a-tooltip class="tooltip" placement="bottom" title="其他">
          <i class="iconfont icon-undo"></i>
        </a-tooltip> -->
      </div>
      <div class="right" v-if="!isPreview">
        <!-- <span class="work-id">ST-1-1-11-1</span> -->
        <a-tooltip placement="bottom" :title="$t('save')" @click="saveData">
          <div class="saveModel">
            <i
              class="iconfont icon-save"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
        </a-tooltip>
      </div>
    </div>
    <context-menu>
      <div class="work" id="work"></div>
    </context-menu>
    <div class="work" id="materialWork" v-if="is_pc"></div>
	<!-- <div style="width: 200px;height: 200px;border: 1px solid #999;position: absolute;z-index: 100;top: 30px;left: 10px;">
		<img width="50%" :src="logoUrl" style="border: 1px solid #999;position: absolute;top: 25%;left: 25%;">
		<a-icon type="arrows-alt" style="position: absolute;left: calc(75% - 7px);top: calc(25% - 6px);"/>
		<a-icon type="redo" style="position: absolute;left: calc(25% - 7px);top:calc(25% - 6px);"/>
		<a-icon type="undo" style="position: absolute;left: calc(25% - 7px);top:calc(75% - 12px);"/>
	</div> -->
    <create-dialog
      @closeModal="closeModal"
      @toEdit="toEdit"
      :add-form="addForm"
      v-if="dialogEnterVisible"
    ></create-dialog>
   <!-- <div class="point" id="point"></div> -->
    <div class="workBottom" v-if="is_pc && !isPreview">
		<a-tooltip placement="bottom" :title="$t('tipBack')">
		  <!-- <a-icon type="left-circle" @click="stepBack"/> -->
		  <i class="iconfont icon-undo" @click="stepBack"></i>
		</a-tooltip>
		<a-tooltip placement="bottom" :title="$t('tipForward')">
			<!-- <a-icon type="right-circle" @click="stepForward"/> -->
			<i class="iconfont icon-Redo" @click="stepForward"></i>
		</a-tooltip>
		<!-- <a-tooltip placement="bottom" title="拉线">
			<a-icon type="ordered-list"  @click="sign"/>
		</a-tooltip> -->
		<a-tooltip placement="bottom" :title="$t('tipColor')">
			 <el-upload
			   style="display: inline-block"
			   action=""
			   accept=".jpg,.jpeg,.png,.JPG,.PNG"
			   :show-file-list="false"
			   ref="upload"
			   :http-request="pickPicture"
			 >
			<a-icon type="ant-design"/>
			 </el-upload>
		</a-tooltip>
		<a-tooltip placement="bottom" :title="$t('tipChange')">
			<a-icon type="redo" @click="peise" v-if="isDesign"/>
		</a-tooltip>
		<a-tooltip v-if="!isVideo && isIframe != 'visaf'" placement="bottom" :title="$t('tipRecordVideo')" style="text-align: center;vertical-align: middle;">
			<svg t="1655880696054" @click="writeAudio" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3214" width="32" height="32"><path d="M729.9072 856.576H40.3456V167.0144h689.5616z m-607.6416-81.92h525.7216V248.9344H122.2656z" fill="#000000" p-id="3215"></path><path d="M983.04 815.7184L647.3728 512 983.04 208.2816zM769.4336 512L901.12 631.1936V392.9088zM483.84 512l-148.0704-101.888v203.776L483.84 512z" fill="#000000" p-id="3216"></path></svg>
		</a-tooltip>
		<a-tooltip v-else-if="isVideo && isIframe != 'visaf'" placement="bottom" :title="$t('tipStopVideo')" style="text-align: center;vertical-align: middle;">
			<svg t="1655880614245" @click="startVideo" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2946" width="32" height="32"><path d="M729.9072 856.576H40.3456V167.0144h689.5616z m-607.6416-81.92h525.7216V248.9344H122.2656z" fill="#000000" p-id="2947"></path><path d="M983.04 815.7184L647.3728 512 983.04 208.2816zM769.4336 512L901.12 631.1936V392.9088zM280.576 410.0096h81.92v203.6736h-81.92zM407.7568 410.0096h81.92v203.6736h-81.92z" fill="#FF0000" p-id="2948"></path></svg>
		</a-tooltip>
		<!-- <a-tooltip placement="bottom" title="保存" @click="saveBaseData">
			<a-icon type="save" />
		</a-tooltip> -->
		<!-- <a-tooltip placement="bottom" title="打印" @click="exportBom">
			<a-icon type="export" />
		</a-tooltip> -->
      <!-- <a-icon type="ant-design" @click="peise"/> -->
     
      <!-- <a-icon type="pie-chart" />
		<a-icon type="bar-chart" /> -->
    </div>
    <iframe
      id="materialIframe"
      style="
        border: none;
        position: absolute;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        display: none;
      "
      src=""
    ></iframe>
    <div
      id="designBox"
      style="
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        top: 0;
        background: #eaeaea;
        display: none;
        text-align: center;
      "
    ></div>
	<el-dialog
		:visible="isShare"
		width="600px"
		:title="$t('preview3d')"
		@close="handleClose"
	>
		<vue-qr :text="preHref" :size="200"></vue-qr>
		<a-icon style="font-size: 30px" type="arrow-right" />
		<img width="300px" :src="resultDesign">
		<div>
			<!-- <a v-if="isIframe === 'visaf'" style="float: right;" @click="goAdmin()">去管理中心</a> -->
			<router-link v-if="isIframe != 'visaf'" style="float: right;" :to="{name:'project-group'}">Go WorkShop</router-link>
		</div>
	</el-dialog>
	<el-dialog
	:visible="audioVisible"
	width="600px"
	@close="handleClose2">
		<el-input
		  type="textarea"	
		  :autosize="{ minRows: 2, maxRows: 4}"
		  :placeholder="$t('tipEnterVoice')"
		  v-model="textarea">
		</el-input>
		<el-input
		  style="margin-top: 20px;"
		  type="text"
		  :placeholder="$t('tipEnterAnimation')"
		  v-model="animationId">
		</el-input>
		<span slot="footer" class="dialog-footer">
		    <el-button @click="audioVisible = false">{{$t('buttonCancel')}}</el-button>
		    <el-button type="primary" @click="startVideo">{{$t('buttonSave')}}</el-button>
		</span>
	</el-dialog>
	<audio loop='loop' id="audio2" style="position: absolute;top: 10px;right: calc(50% - 120px);z-index: -1;" controls="controls" crossorigin="anonymous" :src="audioUrl"></audio>
  </div>
</template>
<script>
import ContextMenu from "@/components/v2/context-menu/ContextMenu";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");

import { ThreeDScene } from "@/utils/babylonjs/methods";
import {
  modelConfig,
  materials,
  materialWorkConfig,
} from "@/utils/babylonjs/meshData";
import * as commonFn from "@/utils/babylonjs/common";
import api from "@/api";
import CreateDialog from "@/components/createDialog/createDialog";
import vueQr from 'vue-qr';
import VueCookies from "vue-cookie";
import uploadFile from "@/utils/upload_to_cos.js";

export default {
  components: { ContextMenu, CreateDialog, vueQr },
  data() {
    return {
      bomtable: null,
      draw_id: null,
      showTools: false,
      showColorPick: false,
      product_id: null,
      file: null,
      dialogEnterVisible: false,
      addForm: {
        name: "",
        delivery_date: "",
        style_number: "",
        style_color: "",
        model_name: "",
        shoe_last_name: "",
        count: "",
        client: "",
        size: "",
      },
      productDetail: {},
      logoData: {
        param: {
          metallic: 0,
          roughness: 1,
          environmentIntensity: 1.5,
          reflectionTexture: {
            url: "texture/environmentSpecular.env",
            type: "env",
            material: null,
          },
          albedoTexture: {
            url: "",
            id: 4,
            type: "color",
            material: [],
            vScale: 1,
            uScale: 1,
          },
        },
        size: { x: 20, y: 20, z: 20 },
        angle: 0,
      },
      pictureData: "",
	  isDesign:false,
	  isShare:false,
	  preHref:'',
	  is_pc:true,
	  resultDesign:'',
	  logoUrl:'',
	  isIframe:'',
	  isPreview:false,
	  recorder:'',
	  isVideo:false,
	  audioVisible:false,
	  textarea:'',
	  animationId:'ATY53R#1',
	  audioUrl:'',
    };
  },
  destroyed() {
    this.global.ModelScene.dorelease();
  },
  computed: {
    ...mapState("saveModelData", "textureSetType"),
  },
  mounted() {
	if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
		document.getElementById("app").setAttribute("style","height:100%");
		this.is_pc = false;
	  }else{
		this.is_pc = true;
	  }
    let query = this.$route.query;
    this.draw_id = query.id;
    this.product_id = query.product_id;
    this.file = query.file;
	this.isIframe = query.iframe;
	this.isPreview = query.isPreview;
    let model_versions = query.model_versions;
    let that = this;
    let loading = this.openLoading();

    let dbConfig = {
      db_name: "BOM",
      db_version: 1,
      table_name: "models",
      table_key: "id",
      model_file: null,
      product_id: null,
    };

    modelConfig.boxId = "work";
    let b = new ThreeDScene(modelConfig);
    this.global.ModelScene = b;
	/* new BABYLON.VideoDome(
		"videoDome",
		["https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/mp4/uptale360.mp4"],
		{
			resolution: 32,
			clickToPlay: true
		},
		b.scene
	); */
	if(!this.product_id && this.file){
		b.loadModel(this.file).then((meshes) => {
			
		});
		loading.close();
	}else if (this.product_id && this.file) {
      this.SET_MODEL_DATA({});
      dbConfig.model_file = this.file;
      dbConfig.product_id = parseInt(this.product_id);
      dbConfig.model_versions = model_versions;
      this.cacheModel(dbConfig).then((result) => {
		b.loadModel(result).then((meshes) => {
			this.SET_PART_LIST(b.partList);
		});
        loading.close();
      });
      this.$api.getProductDetail({ id: this.product_id }).then((res) => {
        that.productDetail = res;
        that.addForm.name = res.name;
        that.addForm.style_number = res.style_number;
        that.addForm.style_color = res.color;
        that.addForm.model_name = res.model_name;
        that.addForm.size = res.size;
		b.partArr = res.file_info.partArr;
		that.textarea = res.intro;
        // let sign = res.file_info.model_sign;
        // Object.keys(sign).forEach(function (key) {
        // 	let position = sign[key].position;
        // 	b.pathColor(position.x,position.y,position.z,sign[key].meshName);
        // })
      });
    } else if (this.draw_id > 0) {
      api.getBom({ id: this.draw_id }).then((res) => {
        that.addForm = res;
        let modelUrl = res.product.file;
        let materials = res.label_attribute;
        let label_matarials = res.label_matarials;
        dbConfig.model_file = modelUrl;
        dbConfig.product_id = parseInt(res.product_id);
        if (res.product) {
          dbConfig.model_versions = res.product.model_versions
            ? res.product.model_versions
            : 0;
			
			b.partArr = res.label.partArr;
			// this.$api.getProductDetail({ id: res.product.id}).then((res) => {
			// 	b.partArr = res.file_info.partArr;
			// });
        }

        this.addForm.name = res.name;
        this.addForm.delivery_date = res.delivery_date;
        this.addForm.style_number = res.style_number;
        this.addForm.style_color = res.style_color;
        this.addForm.model_name = res.model_name;
        this.addForm.shoe_last_name = res.shoe_last_name;
        this.addForm.count = res.count;
        this.addForm.client = res.client;
        this.addForm.size = res.size;

        //加载模型
        this.cacheModel(dbConfig).then((result) => {
          b.loadModel(result).then((meshes) => {
			this.SET_PART_LIST(b.partList);
            let mats = {};
            let label_mats = {};
            materials.forEach((item, index) => {
              if (item && item.mesh_name) mats[item.mesh_name] = item;
              if(item && item.sign){
              	b.pathColor(item.sign.position.x,item.sign.position.y,item.sign.position.z,item.mesh_name);
              }
            });
            label_matarials.forEach((item, index) => {
              label_mats[item.id] = item;
            });
            setTimeout(function () {
              meshes.forEach((item, index) => {
                if (mats[item.name] && label_mats[mats[item.name].texture_id]) {
                  if (mats[item.name].param && mats[item.name].param.alpha) {
                    label_mats[mats[item.name].texture_id].param.alpha =
                      mats[item.name].param.alpha;
                  }
                  if (
                    mats[item.name].param &&
                    mats[item.name].param.albedoTexture
                  ) {
                    label_mats[mats[item.name].texture_id].param.albedoTexture =
                      mats[item.name].param.albedoTexture;
                  }
                  if (
                    mats[item.name].param &&
                    mats[item.name].param.albedoColor
                  ) {
                    label_mats[mats[item.name].texture_id].param.albedoColor =
                      mats[item.name].param.albedoColor;
                    label_mats[
                      mats[item.name].texture_id
                    ].param.reflectionColor = mats[item.name].param.albedoColor;
					
					//亮度
					let val = mats[item.name].param.albedoColor;
					let light = 0.3 * val.r + 0.6 * val.g + 0.1 * val.b;
					light = light < 0.3 ? 0.3 : light;
					label_mats[mats[item.name].texture_id].param.directIntensity = light;
                  }
                  if (
                    mats[item.name].param &&
                    mats[item.name].param.bumpTexture
                  )
                    label_mats[mats[item.name].texture_id].param.bumpTexture =
                      mats[item.name].param.bumpTexture;
                  if (
                    mats[item.name].param &&
                    mats[item.name].param.environmentIntensity
                  ) {
                    label_mats[
                      mats[item.name].texture_id
                    ].param.environmentIntensity =
                      mats[item.name].param.environmentIntensity;
                  }

                  if (label_mats[mats[item.name].texture_id].type == "fur") {
                    item.material = b.getFur(
                      label_mats[mats[item.name].texture_id].param
                    );
                    BABYLON.FurMaterial.FurifyMesh(
                      item,
                      label_mats[mats[item.name].texture_id].param.quality
                    );
                  } else if (
                    label_mats[mats[item.name].texture_id].type == "pbr"
                  ) {
                    item.material = b.getPBR(
                      label_mats[mats[item.name].texture_id].param
                    );
					b.historyData.init[item.name] = label_mats[mats[item.name].texture_id];
                  }
                } else if (mats[item.name] && mats[item.name].param) {
                  item.material = b.getPBR(mats[item.name].param);
                }

                if (mats[item.name] && mats[item.name].logo){
                	mats[item.name].logo.forEach(function(logo){
                		 b.getLogo(item, logo);
                	})
                }
              });
            }, 10);

            that.SET_MODEL_DATA(mats);
            loading.close();
          });
        });
      });
    }

    materialWorkConfig.boxId = "materialWork";
    let b2 = new ThreeDScene(materialWorkConfig);
    this.global.NewMaterialScene = b2;
    document.getElementById("materialWork").style.display = "none";
    var NewMaterialScene = b2;

    let ModelScene = this.global.ModelScene;
    let scene = ModelScene.scene;
    let _this = this;
    let move_length = 0;
    let hl = new BABYLON.HighlightLayer("hl", scene); //高亮线条
    hl.blurHorizontalSize = 0.3;
    hl.blurVerticalSize = 0.3;
    let color = new BABYLON.Color3(0.13, 0.76, 0.66);
    let is_down = false;
    let is_logo = [];
    var is_shift = false;

    let logoSrc = null;
    let materialData = null;
    let imgType = null;
    window.addEventListener("dragstart", function (event) {
      logoSrc = event.target.src;
      materialData = event.target.getAttribute("data");
      imgType = event.target.getAttribute("type");
      console.log(imgType);
      //event.preventDefault()
    });

    window.addEventListener("dragend", function (event) {
      let pickResult;
      let ModelScene2;
      if (document.getElementById("materialWork").style.display == "none") {
        pickResult = scene.pick(event.clientX - 340, event.clientY);
        ModelScene2 = ModelScene;
      } else {
        pickResult = NewMaterialScene.scene.pick(
          event.clientX - 340,
          event.clientY
        );
        ModelScene2 = NewMaterialScene;
      }
      if (pickResult.hit && pickResult.pickedMesh.name.indexOf("[MESHLOGO]") == -1) {
        // hl.removeAllMeshes();
        // hl.addMesh(pickResult.pickedMesh, color);
        ModelScene2.pointer = {
          pointerX: event.clientX - 340,
          pointerY: event.clientY,
        };
        ModelScene2.pickResult = pickResult;
        ModelScene2.pickedMesh = pickResult.pickedMesh;
        _this.logoData.param.albedoTexture.url = logoSrc;
		// ModelScene2.camera.inputs.attached.pointers.detachControl();
  //       ModelScene2.camera.inputs.attached.mousewheel.detachControl();
        //ModelScene.camera.inputs.addGamepad();
        if (imgType == "logo") {
		   _this.logoData.meshName = pickResult.pickedMesh.name;
		  let logoData = JSON.parse(JSON.stringify(_this.logoData));
		  console.log(logoData)
          let data = {
            logoData: logoData,
            ModelScene: ModelScene2,
          };
		
		  _this.logoUrl = logoSrc;
          _this.createLogo(data);
		 // _this.SET_TEXTURE_TYPE('logo');
          setTimeout(function () {
            let pickResult2 = scene.pick(event.clientX - 340, event.clientY);
            if (pickResult2.hit) {
              ModelScene2.highlight.removeAllMeshes();
              ModelScene2.highlight.addMesh(pickResult2.pickedMesh, color);
			  console.log(pickResult2.pickedMesh);
              is_logo = pickResult2.pickedMesh.name.split("[MESHLOGO]");
            }
          }, 500);
        }else if (imgType == "texture") {
			 _this.SET_TEXTURE_TYPE('texture');
          let textureType = JSON.parse(materialData).param.type.name;
          if (textureType == "Color mapping" || !textureType || textureType == "Color Mapping") {
            pickResult.pickedMesh.material.albedoTexture = new BABYLON.Texture(
              logoSrc,
              ModelScene2.scene
            );
			pickResult.pickedMesh.material.albedoTexture.id = JSON.parse(materialData).id;
            // pickResult.pickedMesh.material.albedoColor = new BABYLON.Vector3(1,1,1);
            // //pickResult.pickedMesh.material.reflectionTexture = new BABYLON.Texture(logoSrc, ModelScene2.scene);
            // pickResult.pickedMesh.material.reflectionColor = new BABYLON.Vector3(1,1,1);
            // pickResult.pickedMesh.material.environmentIntensity = 1;
          } else if (textureType == "bump mapping" || textureType == "Bump Mapping") {
            pickResult.pickedMesh.material.bumpTexture = new BABYLON.Texture(
              logoSrc,
              ModelScene2.scene
            );
			pickResult.pickedMesh.material.bumpTexture.id = JSON.parse(materialData).id;
            // pickResult.pickedMesh.material.albedoColor = new BABYLON.Vector3(1,1,1);
            // //pickResult.pickedMesh.material.reflectionColor = new BABYLON.Vector3(1,1,1);
            // pickResult.pickedMesh.material.environmentIntensity = 1;
          }
        } else if (imgType == "material") {
			 // _this.SET_TEXTURE_TYPE('texture');
    //       pickResult.pickedMesh.material = ModelScene2.getPBR(JSON.parse(materialData).param);
        }else{
			console.log(imgType);
		}
      }
    });

    window.addEventListener("dragover", function (event) {});

    window.addEventListener("mousewheel", function (e) {});

	ModelScene.keyboard();
    //点击模型部件后处理程序
    ModelScene.pointerEvent(async function () {
	  if(ModelScene.pickedMesh && ModelScene.pickedMesh.material.albedoColor){
		  let r = ModelScene.pickedMesh.material.albedoColor.r * 255;
		  let g = ModelScene.pickedMesh.material.albedoColor.g * 255;
		  let b = ModelScene.pickedMesh.material.albedoColor.b * 255;
		  let hsl = commonFn.rgbtohsv(r, g, b);
		  if (_this.global.PickrColor) {
		    _this.global.PickrColor.setColor(
		      "rgb(" + r + ", " + g + ", " + b + ")"
		    );
		  }
		  let t = _this.global.ModelScene;
		  if(t.sign[t.pickedMesh.name]){
		  		  _this.SET_PART(t.sign[t.pickedMesh.name].partNum);
		  }
	  }
		
		// WebXRExperienceHelper.CreateAsync(ModelScene.scene).then((xrHelper) => {
		//     // great success
		// }, (error) => {
		// 	console.log(error);
		//     // no xr...
		// })
	  
	  // if(ModelScene.pickedMesh){
		 //  let partType;
		 //  for(let i in ModelScene.partArr){
			//   ModelScene.partArr[i].forEach(function(item){
			// 	  if(item.name == ModelScene.pickedMesh.name){
			// 		  partType = i.split('part')[1];
			// 	  }
			//   })
		 //  }
		 //  _this.SET_PART_TYPE([partType]);
		 //  _this.getMaterialList();
	  // }
    });
  },
  methods: {
    ...mapMutations([
      "SET_SCENE",
      "SET_MODEL_DATA",
      "SET_PICKED_MATERIAL",
      "SET_DEFAULT_VALUE",
	  "SET_TEXTURE_TYPE",
	  "SET_PART",
	  "SET_PART_LIST",
	  "SET_PART_TYPE"
    ]),
    ...mapActions(["saveModelData", "createLogo", "getLogo", "moveLogo","getMaterialList"]),
    ...mapGetters(["pickedMaterial","getPartList"]),
    saveData() {
      this.dialogEnterVisible = true;
      // if(this.product_id){
      //  this.dialogEnterVisible = true;
      // }else if(this.draw_id){
      //  this.toEdit();
      // }
    },
    toEdit() {
	  let that = this;
      this.dialogEnterVisible = false;
      let loading = this.openLoading();
      this.bomtable = this.addForm;
      if (this.product_id && this.file) {
        this.bomtable.type = 2;
        this.bomtable.remark = {};
        this.bomtable.product_id = this.product_id;
        this.bomtable.label = {
          three_d_url: this.file,
        };
      }
      let data = {
        bomtable: this.bomtable,
        ModelScene: this.global.ModelScene,
      };
      // this.productDetail.file_info.model_sign = {};
      // let _this = this;
      // let sign = this.global.ModelScene.sign;
      // Object.keys(sign).forEach(function (key) {
      // 	_this.productDetail.file_info.model_sign[key] = {
      // 		text:sign[key].text,
      // 		position:sign[key].position,
      // 		meshName:sign[key].meshName
      // 	}
      // })
      // this.productDetail.file_info.model_part = {};
      // this.$api.editProduct(this.productDetail).then((res) => {
      // 	console.log(res);
      // });
      this.saveModelData(data).then((result) => {
		that.resultDesign = result.label.img.img_url;
		if(that.bomtable.id){
			that.preHref = window.location.origin+'/#/3dDesign?id='+that.bomtable.id;
		}else{
			that.preHref = window.location.origin+'/#/3dDesign?id='+result.id;
		}
        loading.close();
		that.isShare = true;
      });
    },
    backPage() {
      this.$router.back();
    },
    //关闭弹窗
    closeModal() {
      this.dialogEnterVisible = false;
	  this.addForm.delivery_date = '';
	  this.addForm.count = '';
	  this.addForm.client = '';
    },
    cacheModel(dbConfig) {
      return new Promise((resolve) => {
        let _this = this;
        commonFn.db_create(dbConfig).then((db) => {
          if (db) {
            commonFn
              .db_read(db, dbConfig.table_name, dbConfig.product_id)
              .then(async (result) => {
                if (result && result.version == dbConfig.model_versions) {
                  await commonFn.readModel(result.blob).then(async (result) => {
                    resolve(result);
                  });
                } else {
                  commonFn
                    .loadModel(dbConfig.model_file)
                    .then(async (result) => {
                      let data = {
                        id: dbConfig.product_id,
                        blob: result,
                        version: dbConfig.model_versions,
                      };
                      commonFn
                        .db_add(db, dbConfig.table_name, data)
                        .then(async (result) => {
                          commonFn
                            .db_read(
                              db,
                              dbConfig.table_name,
                              dbConfig.product_id
                            )
                            .then(async (result) => {
                              await commonFn
                                .readModel(result.blob)
                                .then(async (result) => {
                                  resolve(result);
                                });
                            });
                        });
                    });
                }
              });
          }
        });
      });
    },
	//返回
    stepBack() {
	  this.global.ModelScene.stepBack();
    },
	//前进
    stepForward() {
	  this.global.ModelScene.stepForward();
    },
    peise() {
      document.getElementById("designBox").style.display = "block";
      document.getElementById("designBox").innerHTML = "";
      this.forColor(0);
    },
    forColor(index) {
      let _this = this;
      let designData = _this.global.ModelScene.designColor(_this.pictureData);
      _this.global.ModelScene.screenShot2().then((file) => {
        let img = document.createElement("img");
        img.src = file;
        img.style.height = "33%";
        img.setAttribute("data", designData);
        img.onclick = function () {
          document.getElementById("designBox").style.display = "none";
          let data = JSON.parse(img.getAttribute("data"));
          _this.global.ModelScene.setColor(data);
		  _this.isDesign = false;
        };
        document.getElementById("designBox").appendChild(img);
        index++;
        if (index < 9) {
          setTimeout(function () {
            _this.forColor(index);
          }, 100);
        }
      });
    },
    sign() {
	  this.global.ModelScene.isPath = !this.global.ModelScene.isPath;
      let sign = this.global.ModelScene.sign;
      Object.keys(sign).forEach(function (key) {
        sign[key].line.isVisible = !sign[key].line.isVisible;
        sign[key].mesh.isVisible = !sign[key].mesh.isVisible;
      });
    },
    pickPicture(file) {
      let that = this;
      let files = file.file;
      // let check = await this.checkImage(files);
      // if (!check) {
      //   this.$message.error("上传图片信息有误");
      //   return false;
      // }
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.crossOrigin = "Anonymous";
        image.onload = function () {
		  that.isDesign = true;
          that.pictureData = image;
          that.peise();
        };
      };
      reader.readAsDataURL(files);
    },
	handleClose(){
		this.isShare = false;
	},
	exportBom(){
		let data = {
			allData:[{
				color:'',
				cover_pic:'',
				name:'',
				nodeNum:1,
				num:1,	
				part_id:1,
				sn:"1",
				technology:"",
				texture_id:"",
				texture_name:'',
				texture_remark_img:""
			}],
			bom_img:this.resultDesign,
			pageData:{
				client:"1",
				count:1,
				delivery_date:'2021-12-23',
				model_name:'sadds',
				name:"sdfsd",
				shoe_last_name:'1',
				size:'1',
				style_color:1,
				style_number:'1212'
			},
			sn:'3424354544',
			remark:{
				textureRemarkList:[]
			}
		}
		data.pageData = this.addForm;
		data = JSON.stringify(data)
		localStorage.setItem('review',data)
		this.$router.push({
		  path: "review",
		});
	},
	saveBaseData(){
		let partArr = this.global.ModelScene.partArr;
		this.productDetail.file_info.partArr = partArr;
		this.$api.editProduct(this.productDetail).then((res) => {});
	},
	goAdmin(){
		window.parent.postMessage({
			cmd:"admin",
			param:{}
		},"*")
	},
	recordVideo(){
		let canvas = document.getElementById("renderCanvas");
		let stream = canvas.captureStream();
		let videoType="video/mp4";
		if(!MediaRecorder.isTypeSupported('video/mp4'))videoType="video/webm";
		
		var audio2;
		if(this.textarea){
			audio2 = document.getElementById("audio2")
			audio2.play();
			var audioStream = audio2.captureStream();
			var stream2= new MediaStream([...stream.getTracks(), ...audioStream.getTracks()]);
			this.recorder = new MediaRecorder(stream2, { mimeType: videoType});
		}else{
			this.recorder = new MediaRecorder(stream, { mimeType: videoType});
		}

		let dataVideo = [];
		this.recorder.ondataavailable = function (event) {
		    if (event.data && event.data.size) {
		        dataVideo.push(event.data);
		    }
		};
		this.recorder.onstop = () => {
		 //结束录制时下载视频
			if(audio2)audio2.pause();
		    let url = URL.createObjectURL(new Blob(dataVideo, { type: videoType }));
			let fileInfo = new File([new Blob(dataVideo, { type: videoType })],'video.webm',{type:'video/webm',lastModified:Date.now()});
			let _this = this;
			let loading = this.$loading({
			  lock: true,
			  text: '视频加载中,请稍后...',
			  spinner: 'el-icon-loading',
			  background: 'rgba(0, 0, 0, 0.7)'
			});
			uploadFile([fileInfo], "animation", (file,fileNames) => {
				let fileUrl = 'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/putAnimation/'+fileNames[0]+'_transcode_20.mp4';
				let setTime = setInterval(function(){
					let isFileExist = _this.isExistFile(fileUrl);
					if(isFileExist){
						clearInterval(setTime);
						var xhr = new XMLHttpRequest();
						xhr.open('get', fileUrl);
						//设置请求头
						xhr.setRequestHeader(window.AUTH_HEADER, window.localStorage.getItem(window.TOKEN_NAME));
						//设置响应类型
						xhr.responseType = 'blob';
						xhr.onload = function (e) {
						    if (this.status == 200) {
						        var blob = this.response;
						        var a = document.createElement('a');
						        var url = URL.createObjectURL(blob);
								a.style.display = 'none';
							    a.target = '_blank'
						        a.href = url;
						        a.download = fileNames[0]+'_transcode_20.mp4';
						        document.body.appendChild(a);
						        a.click();
								document.body.removeChild(a);
						    }
							loading.close();
						};
						xhr.send();
					}
				},1000)
			});
		};

	},
	async startVideo(){
		this.audioVisible = false;
		this.isVideo = !this.isVideo;
		if(this.isVideo){
			//this.global.ModelScene.isRotation = true;
			//let animations = await BABYLON.Animation.ParseFromFileAsync(null, 'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/animation/animations.json');
			if(this.textarea){
				let _this = this;
				await this.$api.getAudio({text:this.textarea,voiceName:"zh-CN-XiaohanNeural"}).then((res) => {
					_this.audioUrl = res;
				});
			}
			if(this.animationId){
				let animations = await BABYLON.Animation.CreateFromSnippetAsync(this.animationId);
				this.global.ModelScene.camera.setPosition(
				  new BABYLON.Vector3(0,0,1000)
				);
				this.global.ModelScene.meshes[0].animations = animations;
				this.global.ModelScene.scene.beginAnimation(this.global.ModelScene.meshes[0], 0, 500, true);
			} 
			this.recordVideo();
			this.recorder.start();
		}else{
			//this.global.ModelScene.isRotation = false;
			this.global.ModelScene.camera.setPosition(
			  new BABYLON.Vector3(325,181,-159)
			);
			this.global.ModelScene.meshes[0].position = new BABYLON.Vector3(0, 0, 0);
			this.global.ModelScene.meshes[0].scaling = new BABYLON.Vector3(1, 1, 1);
			this.global.ModelScene.meshes[0].rotation = new BABYLON.Vector3(0, 0, 0);
			this.global.ModelScene.scene.stopAllAnimations();
			this.recorder.stop();
		}
	},
	writeAudio(){
		//this.textarea = '';
		this.audioVisible = true;
	},
	handleClose2(){
		this.audioVisible = false;
	},
	/**
	 * 判断服务的⽂件是否存在
	 * @param filepath ⽂件地址
	 * @returns {Boolean}  
	 */
	isExistFile(filepath){
		  var xmlhttp;
		  if (window.XMLHttpRequest){
			xmlhttp=new XMLHttpRequest();
		  }else{
			xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
		  }
		  xmlhttp.open("GET",filepath,false);
		  xmlhttp.send();
		  if(xmlhttp.readyState === 4){
			  if(xmlhttp.status === 200) return true;
			  else if(xmlhttp.status === 404) return false; //url不存在
			  else return false;//其他状态
		  }
	}
  },
};
</script>

<style lang="scss">
.work-space {
  width: 100%;
  min-width: 350px;
  height: 100%;
  position: relative;
  // padding: 0 24px;
  // margin-right: 8px;
  // margin-left: 8px;
  flex: 1;
  background-color: rgb(234, 234, 234);
  .tool-bar {
    .iconfont {
      // margin-right: 16px;
      color: #8d8c92;
    }
    display: flex;
    justify-content: space-between;
    height: $tool-bar-height;
    line-height: $tool-bar-height;
    background: rgba(255, 255, 255, 0);
    position: absolute;
    z-index: 100;
    padding: 10px 20px;
    width: calc(100% - 35px);
    .work-id {
      margin-right: 16px;
      font-weight: 700;
    }
  }
  .work {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: rgba(0, 0, 0, 0);
  }
  .color-picker {
    position: absolute;
    bottom: 10px;
  }

  .add-box {
    position: absolute;
    left: calc(50% - 100px);
    top: calc(50% - 100px);
  }
  canvas {
    width: 100%;
    height: 100%;
  }
  .tooltip {
    padding: 0 10px;
  }
  #materialWork {
    position: absolute;
    top: 0;
  }

  .saveModel {
    width: 36px;
    height: 36px;
    background: #21c3a9;
    line-height: 34px;
    border-radius: 20px;
    text-align: center;
  }

  .point,
  .point::before,
  .point::after {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: "";
  }
  .point::before {
    animation: scale 2s infinite;
  }
  .point::after {
    animation: scale2 2s infinite;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
      opacity: 0.9;
    }
    100% {
      transform: scale(6);
      opacity: 0;
    }
  }
  // @keyframes scale2{0%{ transform: scale(1);opacity:.9;}100%{ transform: scale(12);opacity:0;}}

  .point,
  .point::before,
  .point::after {
    /* 设置颜色 */
    background-color: rgba(33, 195, 169, 0.9);
  }
  .point2,
  .point2::before,
  .point2::after {
    /* 设置颜色 */
    background-color: rgba(0, 221, 254, 0.9);
  }
  .point3,
  .point3::before,
  .point3::after {
    /* 设置颜色 */
    background-color: rgba(1, 88, 255, 0.9);
  }
  .point4,
  .point4::before,
  .point4::after {
    /* 设置颜色 */
    background-color: rgba(135, 229, 8, 0.9);
  }
}

.workBottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  text-align: center;
  font-size: 30px;
  z-index: 10000;

  i {
    margin: 10px;
  }
}
</style>
