<template>
  <a-dropdown
    class="dropdwn"
    :trigger="['contextmenu']"
    @visibleChange="changex"
    :visible="is_show"
  >
    <div>
      <slot></slot>
    </div>
    <a-menu slot="overlay">
      <!-- <a-menu-item :key="idx + 1" v-for="(m, idx) in menus">
        {{ m.label }}
      </a-menu-item> -->
      <a-menu-item key="0" @click="handleCancel">
        {{ $t("deleteSelectedMaterials") }}
      </a-menu-item>
      <a-menu-item key="1" @click="handleCancelAll">
        {{ $t("deleteAllMaterials") }}</a-menu-item
      >
      <!-- <a-menu-item key="2" @click="handleCancelLogo"> {{$t('deleteAllLogos')}} </a-menu-item> -->
      <a-menu-item key="2" @click="handleDeleteLogo"> 删除LOGO </a-menu-item>
      <a-menu-item key="3" @click="handleMerge"> 部件合并 </a-menu-item>
      <a-menu-item key="4" @click="handleCancelMerge">
        取消部件合并
      </a-menu-item>
      <a-menu-item key="5" @click="getPosition"> 定义部件最佳视角 </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
export default {
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_show: false,
    };
  },
  mounted() {
    let that = this;
    document.onclick = function () {
      that.is_show = false;
    };
  },
  methods: {
    ...mapActions(["deleteAllLogo", "deleteAllMaterials", "deleteMaterial"]),
    handleCancel() {
      let ModelScene = this.global.ModelScene;
      if (ModelScene.pickedMesh) {
        ModelScene.pickedMesh.material = ModelScene.getPBR(
          ModelScene.config.material.initPBR
        );
        this.deleteMaterial(ModelScene.pickedMesh.name);
      }
    },
    handleCancelAll() {
      let ModelScene = this.global.ModelScene;
      if (ModelScene.meshes) {
        ModelScene.meshes.forEach(function (item) {
          item.material = ModelScene.getPBR(ModelScene.config.material.initPBR);
        });
        this.deleteAllMaterials();
      }
    },
    changex(visible) {
      this.is_show = true;
    },
    handleCancelLogo() {
      let logos = this.global.ModelScene.logos;
      Object.getOwnPropertyNames(logos).forEach(function (key) {
        logos[key].dispose();
      });
      this.deleteAllLogo();
    },
    handleMerge() {
      this.global.ModelScene.mergeMesh();
    },
    getPosition() {
      this.global.ModelScene.getPosition();
    },
    handleCancelMerge() {
      this.global.ModelScene.cancelMerge();
    },
    handleDeleteLogo() {
      this.global.ModelScene.cancelLogo();
    },
  },
};
</script>

<style lang="scss">
.dropdwn {
  height: 100%;
}
</style>
